/**
 * Generated from the-graph-dark.css, the-graph-light.css
 */
const $_documentContainer = document.createElement('template');
$_documentContainer.innerHTML = `
<dom-module id="the-graph-styles">
  <template>
    <style>
      .the-graph-dark .the-graph-app {
  background-color: #000;
  position: relative;
}
.the-graph-dark svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.the-graph-dark .arrow {
  cursor: default;
}
.the-graph-dark .click {
  cursor: pointer;
}
.the-graph-dark .drag {
  cursor: pointer;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}
.the-graph-dark .node-border {
  fill: rgba(0,0,0,0.5);
  stroke: #666;
  stroke-width: 2px;
}
.the-graph-dark .node-rect {
  fill: rgba(230,238,240,0.94);
  stroke: none;
}
@-webkit-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-moz-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-o-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
.the-graph-dark .node.error .node-rect {
  -webkit-animation: error 1s linear infinite alternate;
  -moz-animation: error 1s linear infinite alternate;
  -o-animation: error 1s linear infinite alternate;
  animation: error 1s linear infinite alternate;
  stroke: none;
}
.the-graph-dark .eventcatcher {
  fill: #fff;
  fill-opacity: 0.1;
}
.the-graph-dark .node:hover .node-rect {
  fill: rgba(230,238,240,0.97);
}
.the-graph-dark .node:hover .node-border {
  stroke: #808080;
}
.the-graph-dark .node:hover .port-arc {
  fill: #808080;
}
.the-graph-dark .ex-inports .node-border {
  fill: #1a1a1a;
  stroke: none;
}
.the-graph-dark .ex-outports .node-border {
  fill: #1a1a1a;
  stroke: none;
}
.the-graph-dark .ex-inports .node .node-rect,
.the-graph-dark .ex-outports .node .node-rect,
.the-graph-dark .ex-inports .node:hover .node-rect,
.the-graph-dark .ex-outports .node:hover .node-rect {
  fill: none;
}
.the-graph-dark .selection .ex-inports .node .node-border,
.the-graph-dark .selection .ex-outports .node .node-border {
  fill: #1a1a1a;
}
.the-graph-dark .small .node-border {
  fill: rgba(230,238,240,0.94);
  stroke: none;
}
.the-graph-dark .small .ex-inports .node .node-border,
.the-graph-dark .small .ex-outports .node .node-border {
  fill: #1a1a1a;
}
.the-graph-dark .node-bg {
  opacity: 0;
}
.the-graph-dark .selection .node .node-rect {
  fill: rgba(61,66,67,0.94);
}
.the-graph-dark .selection .node .node-border {
  stroke: #232829;
}
.the-graph-dark .selection .node .port-arc {
  fill: #232829;
}
.the-graph-dark .selection .node .node-icon {
  fill: #232829;
}
.the-graph-dark .selection .node.selected .node-rect {
  fill: rgba(230,238,240,0.94);
}
.the-graph-dark .selection .node.selected .node-border {
  stroke: #808080;
}
.the-graph-dark .selection .node.selected .port-arc {
  fill: #808080;
}
.the-graph-dark .selection .node.selected .node-icon {
  fill: #5e6a6e;
}
.the-graph-dark .selection .ex-inports .node .node-icon {
  fill: #512f01;
}
.the-graph-dark .selection .ex-outports .node .node-icon {
  fill: #015115;
}
.the-graph-dark .small .selection .node .node-rect {
  fill: none;
}
.the-graph-dark .small .selection .node .node-border {
  fill: rgba(61,66,67,0.94);
  stroke: none;
}
.the-graph-dark .small .selection .node.selected .node-rect {
  fill: none;
}
.the-graph-dark .small .selection .node.selected .node-border {
  fill: rgba(230,238,240,0.94);
  stroke: none;
}
.the-graph-dark path {
  fill: none;
}
.the-graph-dark .arrow-bg {
  stroke: #000;
  stroke-width: 2px;
}
.the-graph-dark .edge-bg {
  stroke: #000;
  stroke-width: 5px;
}
.the-graph-dark .edge-fg {
  stroke: #fff;
  stroke-width: 3px;
  transition-property: stroke-width;
  transition-duration: 0.5s;
}
.the-graph-dark .edge-touch {
  stroke-width: 7px;
  opacity: 0;
}
.the-graph-dark .edge:hover .edge-bg,
.the-graph-dark .edge:hover .arrow-bg {
  stroke: #808080;
}
.the-graph-dark .edge.selected .arrow-bg {
  stroke: #e6e6e6;
  stroke-width: 4px;
}
.the-graph-dark .edge.selected .edge-bg {
  stroke-width: 8px;
  stroke: #e6e6e6;
}
.the-graph-dark .small .edge-bg {
  stroke-width: 8px;
}
.the-graph-dark .small .edge-fg {
  stroke-width: 6px;
}
@-webkit-keyframes arrow-heartbeats {
  0% {
    stroke-width: 4px;
  }
  10% {
    stroke-width: 8px;
  }
  30% {
    stroke-width: 4px;
  }
  40% {
    stroke-width: 8px;
  }
  60% {
    stroke-width: 4px;
  }
}
@-moz-keyframes arrow-heartbeats {
  0% {
    stroke-width: 4px;
  }
  10% {
    stroke-width: 8px;
  }
  30% {
    stroke-width: 4px;
  }
  40% {
    stroke-width: 8px;
  }
  60% {
    stroke-width: 4px;
  }
}
@-o-keyframes arrow-heartbeats {
  0% {
    stroke-width: 4px;
  }
  10% {
    stroke-width: 8px;
  }
  30% {
    stroke-width: 4px;
  }
  40% {
    stroke-width: 8px;
  }
  60% {
    stroke-width: 4px;
  }
}
.the-graph-dark .edge.animated .arrow-bg {
  stroke-linecap: round;
  stroke-width: 4px;
  stroke: #e6e6e6;
  -webkit-animation: arrow-heartbeats 4s linear infinite;
  -moz-animation: arrow-heartbeats 4s linear infinite;
  -o-animation: arrow-heartbeats 4s linear infinite;
  animation: arrow-heartbeats 4s linear infinite;
}
@-webkit-keyframes heartbeats {
  0% {
    stroke-width: 6px;
  }
  10% {
    stroke-width: 12px;
  }
  30% {
    stroke-width: 6px;
  }
  40% {
    stroke-width: 12px;
  }
  60% {
    stroke-width: 6px;
  }
}
@-moz-keyframes heartbeats {
  0% {
    stroke-width: 6px;
  }
  10% {
    stroke-width: 12px;
  }
  30% {
    stroke-width: 6px;
  }
  40% {
    stroke-width: 12px;
  }
  60% {
    stroke-width: 6px;
  }
}
@-o-keyframes heartbeats {
  0% {
    stroke-width: 6px;
  }
  10% {
    stroke-width: 12px;
  }
  30% {
    stroke-width: 6px;
  }
  40% {
    stroke-width: 12px;
  }
  60% {
    stroke-width: 6px;
  }
}
.the-graph-dark .edge.animated .edge-bg {
  stroke-linecap: round;
  stroke-width: 6px;
  stroke: #e6e6e6;
  -webkit-animation: heartbeats 4s linear infinite;
  -moz-animation: heartbeats 4s linear infinite;
  -o-animation: heartbeats 4s linear infinite;
  animation: heartbeats 4s linear infinite;
}
.the-graph-dark text {
  font-family: "SourceCodePro", "Source Code Pro", Helvetica, Arial, sans-serif;
  text-rendering: geometricPrecision; /* makes text scale smoothly */
  font-size: 14px;
  fill: #fff;
  text-anchor: middle;
  dominant-baseline: central;
}
.the-graph-dark .text-bg-rect {
  fill: rgba(0,0,0,0.5);
}
.the-graph-dark .node-label,
.the-graph-dark .node-sublabel {
  text-anchor: middle;
}
.the-graph-dark .small .node-label-bg {
  visibility: hidden;
}
.the-graph-dark .node-sublabel {
  font-size: 9px;
}
.the-graph-dark .node-sublabel-bg {
  visibility: hidden;
}
.the-graph-dark .big .node-sublabel-bg {
  visibility: visible;
}
.the-graph-dark .iip-path {
  stroke-width: 1px;
  stroke: #fff;
}
.the-graph-dark .iip-info {
  visibility: hidden;
}
.the-graph-dark .iip-info .text-bg-text {
  font-size: 5px;
  text-anchor: end;
}
.the-graph-dark .big .iip-info {
  visibility: visible;
}
.the-graph-dark .context-modal-bg {
  fill: rgba(0,0,0,0.5);
}
.the-graph-dark .context-node-rect {
  fill: #121417;
  stroke: #bfbfbf;
  stroke-width: 0.5px;
}
.the-graph-dark .context-node-icon {
  font-size: 30px;
  fill: #fff;
}
.the-graph-dark .context-icon {
  font-size: 20px;
  fill: #fff;
}
.the-graph-dark .context-node-label {
  text-anchor: middle;
}
.the-graph-dark .context-arc {
  stroke-width: 72px;
  stroke: rgba(43,48,54,0.95);
}
.the-graph-dark .context-slice.click:hover .context-arc {
  stroke: rgba(90,102,114,0.95);
}
.the-graph-dark .context-circle {
  stroke: #bfbfbf;
  fill: none;
  stroke-width: 1.5px;
}
.the-graph-dark .context-circle-x {
  stroke: #bfbfbf;
  fill: none;
  stroke-width: 1px;
}
.the-graph-dark .context-arc-icon-label {
  font-size: 12px;
}
.the-graph-dark .context-port-bg {
  fill: rgba(43,48,54,0.95);
}
.the-graph-dark .context-port-bg.highlight {
  stroke: #bfbfbf;
  stroke-width: 1px;
}
.the-graph-dark .context-port-hole {
  stroke-width: 2px;
  fill: rgba(43,48,54,0.95);
  stroke: #bfbfbf;
}
.the-graph-dark .context-port-path {
  stroke: #bfbfbf;
}
.the-graph-dark .context-port-label {
  fill: #fff;
  dominant-baseline: central;
}
.the-graph-dark .context-port-in .context-port-label {
  text-anchor: end;
}
.the-graph-dark .context-port-out .context-port-label {
  text-anchor: start;
}
.the-graph-dark .context-edge-label-out {
  text-anchor: end;
  dominant-baseline: central;
}
.the-graph-dark .context-edge-label-in {
  text-anchor: start;
  dominant-baseline: central;
}
.the-graph-dark .tooltip {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.3s;
}
.the-graph-dark .tooltip.hidden {
  opacity: 0;
}
.the-graph-dark .tooltip-bg {
  fill: rgba(0,0,0,0.9);
  opacity: 0.75;
}
.the-graph-dark .tooltip-label {
  text-anchor: start;
  font-size: 10px;
}
.the-graph-dark .icon {
  font-family: 'FontAwesomeSVG';
  text-anchor: middle;
  dominant-baseline: central;
}
.the-graph-dark .node-icon {
  font-size: 45px;
  fill: #5e6a6e;
  transition-property: font-size, fill;
  transition-duration: 0.5s, 0.3s;
}
.the-graph-dark .small .node-icon {
  fill: #2f3537;
  font-size: 66px;
}
.the-graph-dark .big .node-icon {
  fill: #c8ced0;
}
.the-graph-dark .ex-inports .node-icon,
.the-graph-dark .small .ex-inports .node-icon {
  fill: #e88802;
}
.the-graph-dark .ex-outports .node-icon,
.the-graph-dark .small .ex-outports .node-icon {
  fill: #02e83c;
}
.the-graph-dark .port-circle-bg {
  fill: #666;
  opacity: 0;
}
.the-graph-dark .port-arc {
  fill: #666;
}
.the-graph-dark .port-circle-small {
  fill: none;
}
.the-graph-dark .small .port-circle {
  visibility: hidden;
}
.the-graph-dark .port-label {
  fill: #0c0d0e;
  visibility: hidden;
  font-size: 6px;
  dominant-baseline: central;
}
.the-graph-dark .inports .port-label {
  text-anchor: start;
}
.the-graph-dark .outports .port-label {
  text-anchor: end;
}
.the-graph-dark .big .port-label {
  visibility: visible;
}
.the-graph-dark .big .ex-inports .port-label,
.the-graph-dark .big .ex-outports .port-label {
  visibility: hidden;
}
.the-graph-dark .group-box {
  fill: rgba(191,191,191,0.15);
  transition-property: fill;
  transition-duration: 0.3s;
}
.the-graph-dark .group-box:hover {
  fill: rgba(191,191,191,0.25);
}
.the-graph-dark .small .group-box {
  stroke-width: 8px;
}
.the-graph-dark .group-box.color0 {
  fill: rgba(191,191,191,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color1 {
  fill: rgba(255,128,128,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color2 {
  fill: rgba(255,202,128,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color3 {
  fill: rgba(255,255,128,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color4 {
  fill: rgba(128,255,159,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color5 {
  fill: rgba(128,255,212,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color6 {
  fill: rgba(128,244,255,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color7 {
  fill: rgba(128,191,255,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color8 {
  fill: rgba(223,128,255,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color9 {
  fill: rgba(255,128,234,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color10 {
  fill: rgba(255,128,181,0.15);
  stroke: none;
}
.the-graph-dark .group-box.color0:hover {
  fill: rgba(191,191,191,0.25);
}
.the-graph-dark .group-box.color1:hover {
  fill: rgba(255,128,128,0.25);
}
.the-graph-dark .group-box.color2:hover {
  fill: rgba(255,202,128,0.25);
}
.the-graph-dark .group-box.color3:hover {
  fill: rgba(255,255,128,0.25);
}
.the-graph-dark .group-box.color4:hover {
  fill: rgba(128,255,159,0.25);
}
.the-graph-dark .group-box.color5:hover {
  fill: rgba(128,255,212,0.25);
}
.the-graph-dark .group-box.color6:hover {
  fill: rgba(128,244,255,0.25);
}
.the-graph-dark .group-box.color7:hover {
  fill: rgba(128,191,255,0.25);
}
.the-graph-dark .group-box.color8:hover {
  fill: rgba(223,128,255,0.25);
}
.the-graph-dark .group-box.color9:hover {
  fill: rgba(255,128,234,0.25);
}
.the-graph-dark .group-box.color10:hover {
  fill: rgba(255,128,181,0.25);
}
.the-graph-dark .selection .group-box.color0 {
  fill: rgba(191,191,191,0.1);
}
.the-graph-dark .selection .group-box.color1 {
  fill: rgba(255,128,128,0.1);
}
.the-graph-dark .selection .group-box.color2 {
  fill: rgba(255,202,128,0.1);
}
.the-graph-dark .selection .group-box.color3 {
  fill: rgba(255,255,128,0.1);
}
.the-graph-dark .selection .group-box.color4 {
  fill: rgba(128,255,159,0.1);
}
.the-graph-dark .selection .group-box.color5 {
  fill: rgba(128,255,212,0.1);
}
.the-graph-dark .selection .group-box.color6 {
  fill: rgba(128,244,255,0.1);
}
.the-graph-dark .selection .group-box.color7 {
  fill: rgba(128,191,255,0.1);
}
.the-graph-dark .selection .group-box.color8 {
  fill: rgba(223,128,255,0.1);
}
.the-graph-dark .selection .group-box.color9 {
  fill: rgba(255,128,234,0.1);
}
.the-graph-dark .selection .group-box.color10 {
  fill: rgba(255,128,181,0.1);
}
.the-graph-dark .selection .group-box.selection {
  fill: rgba(255,255,255,0.15);
  stroke: #e6e6e6;
  stroke-width: 1px;
}
.the-graph-dark .selection .group-box.selection:hover {
  fill: rgba(255,255,255,0.25);
}
.the-graph-dark .group-label {
  text-anchor: start;
  fill: #fff;
  font-size: 20px;
  transition-property: font-size;
  transition-duration: 0.5s;
}
.the-graph-dark .small .group-label {
  font-size: 30px;
  transition-property: font-size;
  transition-duration: 0.5s;
}
.the-graph-dark .group-description {
  fill: #fff;
  font-size: 12px;
  text-anchor: start;
}
.the-graph-dark .small .group-description {
  visibility: hidden;
}
.the-graph-dark .stroke,
.the-graph-dark .stroke.route0,
.the-graph-dark .selection .selected .stroke,
.the-graph-dark .selection .selected .stroke.route0 {
  stroke: #fff;
}
.the-graph-dark .stroke.route1,
.the-graph-dark .selection .selected .stroke.route1 {
  stroke: #e80202;
}
.the-graph-dark .stroke.route2,
.the-graph-dark .selection .selected .stroke.route2 {
  stroke: #e88802;
}
.the-graph-dark .stroke.route3,
.the-graph-dark .selection .selected .stroke.route3 {
  stroke: #e8e802;
}
.the-graph-dark .stroke.route4,
.the-graph-dark .selection .selected .stroke.route4 {
  stroke: #02e83c;
}
.the-graph-dark .stroke.route5,
.the-graph-dark .selection .selected .stroke.route5 {
  stroke: #02e89c;
}
.the-graph-dark .stroke.route6,
.the-graph-dark .selection .selected .stroke.route6 {
  stroke: #02d5e8;
}
.the-graph-dark .stroke.route7,
.the-graph-dark .selection .selected .stroke.route7 {
  stroke: #0275e8;
}
.the-graph-dark .stroke.route8,
.the-graph-dark .selection .selected .stroke.route8 {
  stroke: #af02e8;
}
.the-graph-dark .stroke.route9,
.the-graph-dark .selection .selected .stroke.route9 {
  stroke: #e802c2;
}
.the-graph-dark .stroke.route10,
.the-graph-dark .selection .selected .stroke.route10 {
  stroke: #e80262;
}
.the-graph-dark .selection .stroke,
.the-graph-dark .selection .stroke.route0 {
  stroke: #757575;
}
.the-graph-dark .selection .stroke.route1 {
  stroke: #510101;
}
.the-graph-dark .selection .stroke.route2 {
  stroke: #512f01;
}
.the-graph-dark .selection .stroke.route3 {
  stroke: #515101;
}
.the-graph-dark .selection .stroke.route4 {
  stroke: #015115;
}
.the-graph-dark .selection .stroke.route5 {
  stroke: #015136;
}
.the-graph-dark .selection .stroke.route6 {
  stroke: #014a51;
}
.the-graph-dark .selection .stroke.route7 {
  stroke: #012951;
}
.the-graph-dark .selection .stroke.route8 {
  stroke: #3d0151;
}
.the-graph-dark .selection .stroke.route9 {
  stroke: #510143;
}
.the-graph-dark .selection .stroke.route10 {
  stroke: #510122;
}
.the-graph-dark .fill,
.the-graph-dark .fill.route0,
.the-graph-dark .selection .selected .fill,
.the-graph-dark .selection .selected .fill.route0 {
  fill: #fff;
}
.the-graph-dark .fill.route1,
.the-graph-dark .selection .selected .fill.route1 {
  fill: #e80202;
}
.the-graph-dark .fill.route2,
.the-graph-dark .selection .selected .fill.route2 {
  fill: #e88802;
}
.the-graph-dark .fill.route3,
.the-graph-dark .selection .selected .fill.route3 {
  fill: #e8e802;
}
.the-graph-dark .fill.route4,
.the-graph-dark .selection .selected .fill.route4 {
  fill: #02e83c;
}
.the-graph-dark .fill.route5,
.the-graph-dark .selection .selected .fill.route5 {
  fill: #02e89c;
}
.the-graph-dark .fill.route6,
.the-graph-dark .selection .selected .fill.route6 {
  fill: #02d5e8;
}
.the-graph-dark .fill.route7,
.the-graph-dark .selection .selected .fill.route7 {
  fill: #0275e8;
}
.the-graph-dark .fill.route8,
.the-graph-dark .selection .selected .fill.route8 {
  fill: #af02e8;
}
.the-graph-dark .fill.route9,
.the-graph-dark .selection .selected .fill.route9 {
  fill: #e802c2;
}
.the-graph-dark .fill.route10,
.the-graph-dark .selection .selected .fill.route10 {
  fill: #e80262;
}
.the-graph-dark .selection .fill,
.the-graph-dark .selection .fill.route0 {
  fill: #757575;
}
.the-graph-dark .selection .fill.route1 {
  fill: #510101;
}
.the-graph-dark .selection .fill.route2 {
  fill: #512f01;
}
.the-graph-dark .selection .fill.route3 {
  fill: #515101;
}
.the-graph-dark .selection .fill.route4 {
  fill: #015115;
}
.the-graph-dark .selection .fill.route5 {
  fill: #015136;
}
.the-graph-dark .selection .fill.route6 {
  fill: #014a51;
}
.the-graph-dark .selection .fill.route7 {
  fill: #012951;
}
.the-graph-dark .selection .fill.route8 {
  fill: #3d0151;
}
.the-graph-dark .selection .fill.route9 {
  fill: #510143;
}
.the-graph-dark .selection .fill.route10 {
  fill: #510122;
}
@-moz-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-webkit-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-o-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-moz-keyframes arrow-heartbeats {
  0% {
    stroke-width: 4px;
  }
  10% {
    stroke-width: 8px;
  }
  30% {
    stroke-width: 4px;
  }
  40% {
    stroke-width: 8px;
  }
  60% {
    stroke-width: 4px;
  }
}
@-webkit-keyframes arrow-heartbeats {
  0% {
    stroke-width: 4px;
  }
  10% {
    stroke-width: 8px;
  }
  30% {
    stroke-width: 4px;
  }
  40% {
    stroke-width: 8px;
  }
  60% {
    stroke-width: 4px;
  }
}
@-o-keyframes arrow-heartbeats {
  0% {
    stroke-width: 4px;
  }
  10% {
    stroke-width: 8px;
  }
  30% {
    stroke-width: 4px;
  }
  40% {
    stroke-width: 8px;
  }
  60% {
    stroke-width: 4px;
  }
}
@keyframes arrow-heartbeats {
  0% {
    stroke-width: 4px;
  }
  10% {
    stroke-width: 8px;
  }
  30% {
    stroke-width: 4px;
  }
  40% {
    stroke-width: 8px;
  }
  60% {
    stroke-width: 4px;
  }
}
@-moz-keyframes heartbeats {
  0% {
    stroke-width: 6px;
  }
  10% {
    stroke-width: 12px;
  }
  30% {
    stroke-width: 6px;
  }
  40% {
    stroke-width: 12px;
  }
  60% {
    stroke-width: 6px;
  }
}
@-webkit-keyframes heartbeats {
  0% {
    stroke-width: 6px;
  }
  10% {
    stroke-width: 12px;
  }
  30% {
    stroke-width: 6px;
  }
  40% {
    stroke-width: 12px;
  }
  60% {
    stroke-width: 6px;
  }
}
@-o-keyframes heartbeats {
  0% {
    stroke-width: 6px;
  }
  10% {
    stroke-width: 12px;
  }
  30% {
    stroke-width: 6px;
  }
  40% {
    stroke-width: 12px;
  }
  60% {
    stroke-width: 6px;
  }
}
@keyframes heartbeats {
  0% {
    stroke-width: 6px;
  }
  10% {
    stroke-width: 12px;
  }
  30% {
    stroke-width: 6px;
  }
  40% {
    stroke-width: 12px;
  }
  60% {
    stroke-width: 6px;
  }
}

.the-graph-light .the-graph-app {
  background-color: #fff;
  position: relative;
}
.the-graph-light svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.the-graph-light .arrow {
  cursor: default;
}
.the-graph-light .click {
  cursor: pointer;
}
.the-graph-light .drag {
  cursor: pointer;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}
.the-graph-light .node-border {
  fill: rgba(255,255,255,0.5);
  stroke: #3b4245;
  stroke-width: 2px;
}
.the-graph-light .node-rect {
  fill: rgba(230,238,240,0.94);
  stroke: none;
}
@-webkit-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-moz-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-o-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
.the-graph-light .node.error .node-rect {
  -webkit-animation: error 1s linear infinite alternate;
  -moz-animation: error 1s linear infinite alternate;
  -o-animation: error 1s linear infinite alternate;
  animation: error 1s linear infinite alternate;
  stroke: none;
}
.the-graph-light .eventcatcher {
  fill: #000;
  fill-opacity: 0.1;
}
.the-graph-light .node:hover .node-rect {
  fill: rgba(230,238,240,0.97);
}
.the-graph-light .node:hover .node-border {
  stroke: #808080;
}
.the-graph-light .node:hover .port-arc {
  fill: #808080;
}
.the-graph-light .ex-inports .node-border {
  fill: #e6e6e6;
  stroke: none;
}
.the-graph-light .ex-outports .node-border {
  fill: #e6e6e6;
  stroke: none;
}
.the-graph-light .ex-inports .node .node-rect,
.the-graph-light .ex-outports .node .node-rect,
.the-graph-light .ex-inports .node:hover .node-rect,
.the-graph-light .ex-outports .node:hover .node-rect {
  fill: none;
}
.the-graph-light .selection .ex-inports .node .node-border,
.the-graph-light .selection .ex-outports .node .node-border {
  fill: #e6e6e6;
}
.the-graph-light .small .node-border {
  fill: rgba(230,238,240,0.94);
  stroke: none;
}
.the-graph-light .small .ex-inports .node .node-border,
.the-graph-light .small .ex-outports .node .node-border {
  fill: #e6e6e6;
}
.the-graph-light .node-bg {
  opacity: 0;
}
.the-graph-light .selection .node .node-rect {
  fill: rgba(245,248,249,0.94);
}
.the-graph-light .selection .node .node-border {
  stroke: #d6dadc;
}
.the-graph-light .selection .node .port-arc {
  fill: #d6dadc;
}
.the-graph-light .selection .node .node-icon {
  fill: #bac2c4;
}
.the-graph-light .selection .node.selected .node-rect {
  fill: rgba(230,238,240,0.94);
}
.the-graph-light .selection .node.selected .node-border {
  stroke: #808080;
}
.the-graph-light .selection .node.selected .port-arc {
  fill: #808080;
}
.the-graph-light .selection .node.selected .node-icon {
  fill: #2f3537;
}
.the-graph-light .selection .ex-inports .node .node-icon {
  fill: #ffe5cc;
}
.the-graph-light .selection .ex-outports .node .node-icon {
  fill: #ccffdb;
}
.the-graph-light .small .selection .node .node-rect {
  fill: none;
}
.the-graph-light .small .selection .node .node-border {
  fill: rgba(245,248,249,0.94);
  stroke: none;
}
.the-graph-light .small .selection .node.selected .node-rect {
  fill: none;
}
.the-graph-light .small .selection .node.selected .node-border {
  fill: rgba(230,238,240,0.94);
  stroke: none;
}
.the-graph-light path {
  fill: none;
}
.the-graph-light .arrow-bg {
  stroke: #e6e6e6;
  stroke-width: 2.5px;
}
.the-graph-light .edge-bg {
  stroke: #e6e6e6;
  stroke-width: 6px;
}
.the-graph-light .edge-fg {
  stroke: #bfbfbf;
  stroke-width: 4px;
  transition-property: stroke-width;
  transition-duration: 0.5s;
}
.the-graph-light .edge-touch {
  stroke-width: 8px;
  opacity: 0;
}
.the-graph-light .edge:hover .edge-bg,
.the-graph-light .edge:hover .arrow-bg {
  stroke: #808080;
}
.the-graph-light .edge.selected .arrow-bg {
  stroke: #808080;
  stroke-width: 5px;
}
.the-graph-light .edge.selected .edge-bg {
  stroke-width: 10px;
  stroke: #808080;
}
.the-graph-light .small .edge-bg {
  stroke-width: 10px;
}
.the-graph-light .small .edge-fg {
  stroke-width: 8px;
}
@-webkit-keyframes arrow-heartbeats {
  0% {
    stroke-width: 5px;
  }
  10% {
    stroke-width: 10px;
  }
  30% {
    stroke-width: 5px;
  }
  40% {
    stroke-width: 10px;
  }
  60% {
    stroke-width: 5px;
  }
}
@-moz-keyframes arrow-heartbeats {
  0% {
    stroke-width: 5px;
  }
  10% {
    stroke-width: 10px;
  }
  30% {
    stroke-width: 5px;
  }
  40% {
    stroke-width: 10px;
  }
  60% {
    stroke-width: 5px;
  }
}
@-o-keyframes arrow-heartbeats {
  0% {
    stroke-width: 5px;
  }
  10% {
    stroke-width: 10px;
  }
  30% {
    stroke-width: 5px;
  }
  40% {
    stroke-width: 10px;
  }
  60% {
    stroke-width: 5px;
  }
}
.the-graph-light .edge.animated .arrow-bg {
  stroke-linecap: round;
  stroke-width: 5px;
  stroke: #808080;
  -webkit-animation: arrow-heartbeats 4s linear infinite;
  -moz-animation: arrow-heartbeats 4s linear infinite;
  -o-animation: arrow-heartbeats 4s linear infinite;
  animation: arrow-heartbeats 4s linear infinite;
}
@-webkit-keyframes heartbeats {
  0% {
    stroke-width: 8px;
  }
  10% {
    stroke-width: 16px;
  }
  30% {
    stroke-width: 8px;
  }
  40% {
    stroke-width: 16px;
  }
  60% {
    stroke-width: 8px;
  }
}
@-moz-keyframes heartbeats {
  0% {
    stroke-width: 8px;
  }
  10% {
    stroke-width: 16px;
  }
  30% {
    stroke-width: 8px;
  }
  40% {
    stroke-width: 16px;
  }
  60% {
    stroke-width: 8px;
  }
}
@-o-keyframes heartbeats {
  0% {
    stroke-width: 8px;
  }
  10% {
    stroke-width: 16px;
  }
  30% {
    stroke-width: 8px;
  }
  40% {
    stroke-width: 16px;
  }
  60% {
    stroke-width: 8px;
  }
}
.the-graph-light .edge.animated .edge-bg {
  stroke-linecap: round;
  stroke-width: 8px;
  stroke: #808080;
  -webkit-animation: heartbeats 4s linear infinite;
  -moz-animation: heartbeats 4s linear infinite;
  -o-animation: heartbeats 4s linear infinite;
  animation: heartbeats 4s linear infinite;
}
.the-graph-light text {
  font-family: "SourceCodePro", "Source Code Pro", Helvetica, Arial, sans-serif;
  text-rendering: geometricPrecision; /* makes text scale smoothly */
  font-size: 14px;
  fill: #000;
  text-anchor: middle;
  dominant-baseline: central;
}
.the-graph-light .text-bg-rect {
  fill: rgba(255,255,255,0.5);
}
.the-graph-light .node-label,
.the-graph-light .node-sublabel {
  text-anchor: middle;
}
.the-graph-light .small .node-label-bg {
  visibility: hidden;
}
.the-graph-light .node-sublabel {
  font-size: 9px;
}
.the-graph-light .node-sublabel-bg {
  visibility: hidden;
}
.the-graph-light .big .node-sublabel-bg {
  visibility: visible;
}
.the-graph-light .iip-path {
  stroke-width: 1px;
  stroke: #bfbfbf;
}
.the-graph-light .iip-info {
  visibility: hidden;
}
.the-graph-light .iip-info .text-bg-text {
  font-size: 5px;
  text-anchor: end;
}
.the-graph-light .big .iip-info {
  visibility: visible;
}
.the-graph-light .context-modal-bg {
  fill: rgba(255,255,255,0.5);
}
.the-graph-light .context-node-rect {
  fill: #e0e3e6;
  stroke: #404040;
  stroke-width: 0.5px;
}
.the-graph-light .context-node-icon {
  font-size: 30px;
  fill: #000;
}
.the-graph-light .context-icon {
  font-size: 20px;
  fill: #000;
}
.the-graph-light .context-node-label {
  text-anchor: middle;
}
.the-graph-light .context-arc {
  stroke-width: 72px;
  stroke: rgba(224,227,230,0.95);
}
.the-graph-light .context-slice.click:hover .context-arc {
  stroke: rgba(195,201,208,0.95);
}
.the-graph-light .context-circle {
  stroke: #404040;
  fill: none;
  stroke-width: 1.5px;
}
.the-graph-light .context-circle-x {
  stroke: #404040;
  fill: none;
  stroke-width: 1px;
}
.the-graph-light .context-arc-icon-label {
  font-size: 12px;
}
.the-graph-light .context-port-bg {
  fill: rgba(224,227,230,0.95);
}
.the-graph-light .context-port-bg.highlight {
  stroke: #404040;
  stroke-width: 1px;
}
.the-graph-light .context-port-hole {
  stroke-width: 2px;
  fill: rgba(224,227,230,0.95);
  stroke: #404040;
}
.the-graph-light .context-port-path {
  stroke: #404040;
}
.the-graph-light .context-port-label {
  fill: #000;
  dominant-baseline: central;
}
.the-graph-light .context-port-in .context-port-label {
  text-anchor: end;
}
.the-graph-light .context-port-out .context-port-label {
  text-anchor: start;
}
.the-graph-light .context-edge-label-out {
  text-anchor: end;
  dominant-baseline: central;
}
.the-graph-light .context-edge-label-in {
  text-anchor: start;
  dominant-baseline: central;
}
.the-graph-light .tooltip {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.3s;
}
.the-graph-light .tooltip.hidden {
  opacity: 0;
}
.the-graph-light .tooltip-bg {
  fill: rgba(255,255,255,0.9);
  opacity: 0.75;
}
.the-graph-light .tooltip-label {
  text-anchor: start;
  font-size: 10px;
}
.the-graph-light .icon {
  font-family: 'FontAwesomeSVG';
  text-anchor: middle;
  dominant-baseline: central;
}
.the-graph-light .node-icon {
  font-size: 45px;
  fill: #3b4245;
  transition-property: font-size, fill;
  transition-duration: 0.5s, 0.3s;
}
.the-graph-light .small .node-icon {
  fill: #171b1c;
  font-size: 66px;
}
.the-graph-light .big .node-icon {
  fill: #c8ced0;
}
.the-graph-light .ex-inports .node-icon,
.the-graph-light .small .ex-inports .node-icon {
  fill: #cc6300;
}
.the-graph-light .ex-outports .node-icon,
.the-graph-light .small .ex-outports .node-icon {
  fill: #00cc3d;
}
.the-graph-light .port-circle-bg {
  fill: #3b4245;
  opacity: 0;
}
.the-graph-light .port-arc {
  fill: #3b4245;
}
.the-graph-light .port-circle-small {
  fill: none;
}
.the-graph-light .small .port-circle {
  visibility: hidden;
}
.the-graph-light .port-label {
  fill: #0c0d0e;
  visibility: hidden;
  font-size: 6px;
  dominant-baseline: central;
}
.the-graph-light .inports .port-label {
  text-anchor: start;
}
.the-graph-light .outports .port-label {
  text-anchor: end;
}
.the-graph-light .big .port-label {
  visibility: visible;
}
.the-graph-light .big .ex-inports .port-label,
.the-graph-light .big .ex-outports .port-label {
  visibility: hidden;
}
.the-graph-light .group-box {
  fill: rgba(191,191,191,0.05);
  transition-property: fill;
  transition-duration: 0.3s;
}
.the-graph-light .group-box:hover {
  fill: rgba(191,191,191,0.15);
}
.the-graph-light .small .group-box {
  stroke-width: 8px;
}
.the-graph-light .group-box.color0 {
  fill: rgba(191,191,191,0.05);
  stroke: rgba(191,191,191,0.15);
}
.the-graph-light .group-box.color1 {
  fill: rgba(204,0,0,0.05);
  stroke: rgba(204,0,0,0.15);
}
.the-graph-light .group-box.color2 {
  fill: rgba(204,99,0,0.05);
  stroke: rgba(204,99,0,0.15);
}
.the-graph-light .group-box.color3 {
  fill: rgba(255,200,0,0.05);
  stroke: rgba(255,200,0,0.15);
}
.the-graph-light .group-box.color4 {
  fill: rgba(0,204,61,0.05);
  stroke: rgba(0,204,61,0.15);
}
.the-graph-light .group-box.color5 {
  fill: rgba(171,242,218,0.05);
  stroke: rgba(171,242,218,0.15);
}
.the-graph-light .group-box.color6 {
  fill: rgba(0,201,204,0.05);
  stroke: rgba(0,201,204,0.15);
}
.the-graph-light .group-box.color7 {
  fill: rgba(0,82,204,0.05);
  stroke: rgba(0,82,204,0.15);
}
.the-graph-light .group-box.color8 {
  fill: rgba(68,0,204,0.05);
  stroke: rgba(68,0,204,0.15);
}
.the-graph-light .group-box.color9 {
  fill: rgba(255,168,186,0.05);
  stroke: rgba(255,168,186,0.15);
}
.the-graph-light .group-box.color10 {
  fill: rgba(204,0,109,0.05);
  stroke: rgba(204,0,109,0.15);
}
.the-graph-light .group-box.color0:hover {
  fill: rgba(191,191,191,0.15);
}
.the-graph-light .group-box.color1:hover {
  fill: rgba(204,0,0,0.15);
}
.the-graph-light .group-box.color2:hover {
  fill: rgba(204,99,0,0.15);
}
.the-graph-light .group-box.color3:hover {
  fill: rgba(255,200,0,0.15);
}
.the-graph-light .group-box.color4:hover {
  fill: rgba(0,204,61,0.15);
}
.the-graph-light .group-box.color5:hover {
  fill: rgba(171,242,218,0.15);
}
.the-graph-light .group-box.color6:hover {
  fill: rgba(0,201,204,0.15);
}
.the-graph-light .group-box.color7:hover {
  fill: rgba(0,82,204,0.15);
}
.the-graph-light .group-box.color8:hover {
  fill: rgba(68,0,204,0.15);
}
.the-graph-light .group-box.color9:hover {
  fill: rgba(255,168,186,0.15);
}
.the-graph-light .group-box.color10:hover {
  fill: rgba(204,0,109,0.15);
}
.the-graph-light .selection .group-box.color0 {
  fill: rgba(191,191,191,0.01);
}
.the-graph-light .selection .group-box.color1 {
  fill: rgba(204,0,0,0.01);
}
.the-graph-light .selection .group-box.color2 {
  fill: rgba(204,99,0,0.01);
}
.the-graph-light .selection .group-box.color3 {
  fill: rgba(255,200,0,0.01);
}
.the-graph-light .selection .group-box.color4 {
  fill: rgba(0,204,61,0.01);
}
.the-graph-light .selection .group-box.color5 {
  fill: rgba(171,242,218,0.01);
}
.the-graph-light .selection .group-box.color6 {
  fill: rgba(0,201,204,0.01);
}
.the-graph-light .selection .group-box.color7 {
  fill: rgba(0,82,204,0.01);
}
.the-graph-light .selection .group-box.color8 {
  fill: rgba(68,0,204,0.01);
}
.the-graph-light .selection .group-box.color9 {
  fill: rgba(255,168,186,0.01);
}
.the-graph-light .selection .group-box.color10 {
  fill: rgba(204,0,109,0.01);
}
.the-graph-light .selection .group-box.selection {
  fill: rgba(128,128,128,0.15);
  stroke: #808080;
  stroke-width: 1px;
}
.the-graph-light .selection .group-box.selection:hover {
  fill: rgba(102,102,102,0.25);
}
.the-graph-light .group-label {
  text-anchor: start;
  fill: #000;
  font-size: 20px;
  transition-property: font-size;
  transition-duration: 0.5s;
}
.the-graph-light .small .group-label {
  font-size: 30px;
  transition-property: font-size;
  transition-duration: 0.5s;
}
.the-graph-light .group-description {
  fill: #000;
  font-size: 12px;
  text-anchor: start;
}
.the-graph-light .small .group-description {
  visibility: hidden;
}
.the-graph-light .stroke,
.the-graph-light .stroke.route0,
.the-graph-light .selection .selected .stroke,
.the-graph-light .selection .selected .stroke.route0 {
  stroke: #bfbfbf;
}
.the-graph-light .stroke.route1,
.the-graph-light .selection .selected .stroke.route1 {
  stroke: #c00;
}
.the-graph-light .stroke.route2,
.the-graph-light .selection .selected .stroke.route2 {
  stroke: #cc6300;
}
.the-graph-light .stroke.route3,
.the-graph-light .selection .selected .stroke.route3 {
  stroke: #ffc800;
}
.the-graph-light .stroke.route4,
.the-graph-light .selection .selected .stroke.route4 {
  stroke: #00cc3d;
}
.the-graph-light .stroke.route5,
.the-graph-light .selection .selected .stroke.route5 {
  stroke: #abf2da;
}
.the-graph-light .stroke.route6,
.the-graph-light .selection .selected .stroke.route6 {
  stroke: #00c9cc;
}
.the-graph-light .stroke.route7,
.the-graph-light .selection .selected .stroke.route7 {
  stroke: #0052cc;
}
.the-graph-light .stroke.route8,
.the-graph-light .selection .selected .stroke.route8 {
  stroke: #40c;
}
.the-graph-light .stroke.route9,
.the-graph-light .selection .selected .stroke.route9 {
  stroke: #ffa8ba;
}
.the-graph-light .stroke.route10,
.the-graph-light .selection .selected .stroke.route10 {
  stroke: #cc006d;
}
.the-graph-light .selection .stroke,
.the-graph-light .selection .stroke.route0 {
  stroke: #e6e6e6;
}
.the-graph-light .selection .stroke.route1 {
  stroke: #fcc;
}
.the-graph-light .selection .stroke.route2 {
  stroke: #ffe5cc;
}
.the-graph-light .selection .stroke.route3 {
  stroke: #fff4cc;
}
.the-graph-light .selection .stroke.route4 {
  stroke: #ccffdb;
}
.the-graph-light .selection .stroke.route5 {
  stroke: #d3f8ec;
}
.the-graph-light .selection .stroke.route6 {
  stroke: #ccfeff;
}
.the-graph-light .selection .stroke.route7 {
  stroke: #cce0ff;
}
.the-graph-light .selection .stroke.route8 {
  stroke: #dcf;
}
.the-graph-light .selection .stroke.route9 {
  stroke: #ffccd6;
}
.the-graph-light .selection .stroke.route10 {
  stroke: #ffcce7;
}
.the-graph-light .fill,
.the-graph-light .fill.route0,
.the-graph-light .selection .selected .fill,
.the-graph-light .selection .selected .fill.route0 {
  fill: #bfbfbf;
}
.the-graph-light .fill.route1,
.the-graph-light .selection .selected .fill.route1 {
  fill: #c00;
}
.the-graph-light .fill.route2,
.the-graph-light .selection .selected .fill.route2 {
  fill: #cc6300;
}
.the-graph-light .fill.route3,
.the-graph-light .selection .selected .fill.route3 {
  fill: #ffc800;
}
.the-graph-light .fill.route4,
.the-graph-light .selection .selected .fill.route4 {
  fill: #00cc3d;
}
.the-graph-light .fill.route5,
.the-graph-light .selection .selected .fill.route5 {
  fill: #abf2da;
}
.the-graph-light .fill.route6,
.the-graph-light .selection .selected .fill.route6 {
  fill: #00c9cc;
}
.the-graph-light .fill.route7,
.the-graph-light .selection .selected .fill.route7 {
  fill: #0052cc;
}
.the-graph-light .fill.route8,
.the-graph-light .selection .selected .fill.route8 {
  fill: #40c;
}
.the-graph-light .fill.route9,
.the-graph-light .selection .selected .fill.route9 {
  fill: #ffa8ba;
}
.the-graph-light .fill.route10,
.the-graph-light .selection .selected .fill.route10 {
  fill: #cc006d;
}
.the-graph-light .selection .fill,
.the-graph-light .selection .fill.route0 {
  fill: #e6e6e6;
}
.the-graph-light .selection .fill.route1 {
  fill: #fcc;
}
.the-graph-light .selection .fill.route2 {
  fill: #ffe5cc;
}
.the-graph-light .selection .fill.route3 {
  fill: #fff4cc;
}
.the-graph-light .selection .fill.route4 {
  fill: #ccffdb;
}
.the-graph-light .selection .fill.route5 {
  fill: #d3f8ec;
}
.the-graph-light .selection .fill.route6 {
  fill: #ccfeff;
}
.the-graph-light .selection .fill.route7 {
  fill: #cce0ff;
}
.the-graph-light .selection .fill.route8 {
  fill: #dcf;
}
.the-graph-light .selection .fill.route9 {
  fill: #ffccd6;
}
.the-graph-light .selection .fill.route10 {
  fill: #ffcce7;
}
@-moz-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-webkit-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-o-keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@keyframes error {
  0% {
    fill: rgba(230,238,240,0.94);
  }
  100% {
    fill: rgba(255,0,0,0.94);
  }
}
@-moz-keyframes arrow-heartbeats {
  0% {
    stroke-width: 5px;
  }
  10% {
    stroke-width: 10px;
  }
  30% {
    stroke-width: 5px;
  }
  40% {
    stroke-width: 10px;
  }
  60% {
    stroke-width: 5px;
  }
}
@-webkit-keyframes arrow-heartbeats {
  0% {
    stroke-width: 5px;
  }
  10% {
    stroke-width: 10px;
  }
  30% {
    stroke-width: 5px;
  }
  40% {
    stroke-width: 10px;
  }
  60% {
    stroke-width: 5px;
  }
}
@-o-keyframes arrow-heartbeats {
  0% {
    stroke-width: 5px;
  }
  10% {
    stroke-width: 10px;
  }
  30% {
    stroke-width: 5px;
  }
  40% {
    stroke-width: 10px;
  }
  60% {
    stroke-width: 5px;
  }
}
@keyframes arrow-heartbeats {
  0% {
    stroke-width: 5px;
  }
  10% {
    stroke-width: 10px;
  }
  30% {
    stroke-width: 5px;
  }
  40% {
    stroke-width: 10px;
  }
  60% {
    stroke-width: 5px;
  }
}
@-moz-keyframes heartbeats {
  0% {
    stroke-width: 8px;
  }
  10% {
    stroke-width: 16px;
  }
  30% {
    stroke-width: 8px;
  }
  40% {
    stroke-width: 16px;
  }
  60% {
    stroke-width: 8px;
  }
}
@-webkit-keyframes heartbeats {
  0% {
    stroke-width: 8px;
  }
  10% {
    stroke-width: 16px;
  }
  30% {
    stroke-width: 8px;
  }
  40% {
    stroke-width: 16px;
  }
  60% {
    stroke-width: 8px;
  }
}
@-o-keyframes heartbeats {
  0% {
    stroke-width: 8px;
  }
  10% {
    stroke-width: 16px;
  }
  30% {
    stroke-width: 8px;
  }
  40% {
    stroke-width: 16px;
  }
  60% {
    stroke-width: 8px;
  }
}
@keyframes heartbeats {
  0% {
    stroke-width: 8px;
  }
  10% {
    stroke-width: 16px;
  }
  30% {
    stroke-width: 8px;
  }
  40% {
    stroke-width: 16px;
  }
  60% {
    stroke-width: 8px;
  }
}

    </style>
  </template>
</dom-module>`;
document.head.appendChild($_documentContainer.content);
